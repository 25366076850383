import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = (props) => {
    const [slideData, setSlideData] = useState([]);
    const [aboutData, setAboutData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [phoneNo, setPhoneNo] = useState("");
    const [processData, setProcessData] = useState([]);
    const [testoData, setTestData] = useState([]);
    const [homeData, setHomeData] = useState([]);
    const [countData, setCountData] = useState([]);
    const [slideStleData, setSlideStyleData] = useState("");
    const [sliderIndex, setSliderIndex] = useState(0); // State to track active slide

    const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 4, slidesToScroll: 1, autoplay: true };
    const testoSlides = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, autoplay: true };

    const dataTesto = [
        {
            message: "माझ्या व्यावसायिक जीवनातील अनेक समस्या मंत्रशास्त्राच्या मार्गदर्शनाने सोडवल्या. यामुळे माझे काम अधिक यशस्वी झाले.",
            name: 'संजय धा.',
            work: 'व्यावसायिक',
        },
        {
            message: "मंत्रशास्त्राचे अभ्यास केल्यावर मनाची शांती मिळाली. माझ्या जीवनातील अडचणी सोडवण्यासाठी मंत्रांचा वापर फार प्रभावी ठरला.",
            name: 'स्वाती ता.',
            work: 'गृहिणी',
        },
        {
            message: "मंत्रशास्त्राचे शिक्षण घेतल्यावर मी मानसिकदृष्ट्या खूप स्थिर झाले. प्रत्येक अडचणीला तोंड देण्यासाठी मंत्रांची शक्ती माझ्या सोबत होती. माझ्या जीवनातील दुरदर्शन वाढवली आणि आत्मविश्वास वाढला. मनाशी निगडीत असलेल्या सर्व समस्यांचे निराकरण मंत्रांद्वारे सोडवले. मंत्रशास्त्राने जीवनाला एक नवा अर्थ दिला.",
            name: 'सुस्मिता पा.',
            work: 'व्यावसायिक',
        },
        {
            message: "माझ्या जीवनातील अनावश्यक ताण-तणाव मंत्रशास्त्राने दूर केला. प्रत्येक दिवशी साधनेसाठी वेळ काढल्यामुळे मी जीवनात एक नवीन दिशा शोधली. मंत्रांच्या उर्जेने मी उंची गाठली आहे. शारीरिक आणि मानसिक आरोग्य सुधारले. मंत्रशास्त्राने मला जीवनातील प्रत्येक टप्प्यावर मार्गदर्शन केले.",
            name: 'पूजा प.',
            work: 'गृहिणी',
        },
        {
            message: "मंत्रशास्त्राच्या अभ्यासाने माझ्या जीवनात सकारात्मक बदल घडवले. साधनेसाठी आवश्यक असलेल्या शांतीला मिळवले आणि आत्मबल वाढवला. मी अधिक आत्मनिर्भर आणि शांत राहू लागलो. मंत्रांच्या सहाय्याने मी कामामध्ये यशस्वी झालो. त्यामुळे माझे जीवन सुसंवादी आणि समाधानी झाले.",
            name: 'राघिनी घा',
            work: 'गृहिणी',
        },
        {
            message: "मंत्रशास्त्राच्या साधनेने माझ्या कामातील सर्व अडचणी दूर केल्या. माझ्या आयुष्यात मंत्रांच्या मार्गदर्शनाने यश आले. मनाची शांती मिळवून मी चांगले निर्णय घेऊ लागलो. प्रत्येक कार्यात मंत्रांचा उपयोग करत, मी खूप सुधारणा केली. हे ज्ञान केवळ अध्यात्मिकच नाही, तर व्यावसायिक जीवनासाठीही महत्त्वपूर्ण ठरले.",
            name: 'मंगेश जा.',
            work: 'सिनीअर मॅनेजर',
        },
        {
            message: "मंत्रशास्त्राच्या सहाय्याने मी आयुष्यातील अनेक अडचणी सहजपणे पार केल्या. माझ्या कामामध्ये यश मिळवायला मदत झाली.",
            name: 'पूजा जो.',
            work: 'सोफ्टवर इंजीनिअर',
        },
        {
            message: "माझ्या व्यवसायातील संघर्ष सोडवण्यासाठी मंत्रशास्त्राने खूप मदत केली. मंत्रांनी मला आत्मबल आणि ऊर्जा दिली.",
            name: 'निलेश सा.',
            work: 'व्यावसायिक',
        },
        {
            message: "मंत्रशास्त्राच्या मार्गदर्शनाने मी शारीरिक आणि मानसिक आरोग्य सुधारले. जीवनातील उंची गाठण्यासाठी मंत्र उपयोगी ठरले.",
            name: 'शिल्पा ई.',
            work: 'व्यावसायिक',
        },
    ];

    const data = [
        {
            label: `Basic`,
            text: 'Mantra shastra Level 1',
            title: 'Good to start from',
            image: `assets/images/events/level1_mumbai_012020_5.jpeg`,
        },
        {
            label: `Advance`,
            text: 'Mantrashastra Level 2',
            title: 'Next level of Mantras and Yantras',
            image: `assets/images/events/level_2_pune_2020_3.jpeg`,
        },
        {
            label: `Sadhana`,
            text: 'Ganesh Sadhana',
            title: 'Online Ganesh Sadhana',
            image: "assets/images/events/ganesh_sadhana.jfif",
        },
        {
            label: `Guru pooja`,
            text: 'Guru pornima event',
            title: 'Event from sadhak',
            image: "assets/images/events/gurupornima_1.jpeg",
        },
        {
            label: `Sadhana`,
            text: 'Mahashivratri Sadhana',
            title: 'Innovative solutions',
            image: "assets/images/events/mahashivratri.jpeg",
        },
        {
            label: `Sadhana`,
            text: 'Pitrutarpan Sadhana',
            title: 'Vidhi',
            image: "assets/images/events/pitrutarpan.jpeg",
        },
    ]
    useEffect(() => {
        // props.fetchAllCandidates()
        // setSlideStyleData('{ "loop": true, "slidesPerView": 1,"centeredSlides":true,"effect":"coverflow","coverflowEffect":{"rotate":0,"stretch":100,"depth":150,"modifier":1.5,"slideShadows":true}, "navigation": { "nextEl": ".swiper-button-next-nav.slider-navigation-style-04", "prevEl": ".swiper-button-previous-nav.slider-navigation-style-04" }, "autoplay": { "delay": 5000, "disableOnInteraction": false }, "pagination": { "el": ".swiper-pagination-04", "clickable": true, "dynamicBullets": true }, "breakpoints": { "768": { "slidesPerView": 2 } } }');

        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", translateX: [50, 0], opacity: [0, 1], duration: 1000, delay: 300, staggervalue: 500, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-about]', // Select elements with the data attribute
            el: "childs", opacity: [0, 1], duration: 1600, delay: 100, staggervalue: 15, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-about-1]', // Select elements with the data attribute
            el: "childs", translateX: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-count]',
            el: "childs", translateY: [50, 0], opacity: [0, 1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-step]',
            el: "childs", translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            translateX: [0, 0], opacity: [0, 1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-2]',
            translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-3]',
            translateY: [0, 0], opacity: [0, 1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-4]',
            rotateX: [-40, 0], opacity: [0, 1], duration: 1200, delay: 100, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-5]',
            translateY: [0, 0], opacity: [0, 1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-6]',
            el: "childs", translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-7]',
            el: "childs", translateY: [50, 0], opacity: [0, 1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
    }, [])

    // useEffect(() => {
    //     const makeRequest = async () => {
    //         let jsn = await props.data;
    //         if (jsn != null) {
    //             if (jsn.Slides != null) {
    //                 let parsedArray = JSON.parse(jsn.Slides);
    //                 setSlideData(parsedArray);
    //             }
    //             if (jsn.About != null) {
    //                 if (JSON.parse(jsn.About).length) {
    //                     setAboutData(JSON.parse(jsn.About)[0]);
    //                 }
    //             }
    //             if (jsn.HomeProcess != null) {
    //                 if (JSON.parse(jsn.HomeProcess).length) {
    //                     setProcessData(JSON.parse(jsn.HomeProcess)[0]);
    //                 }
    //             }
    //             if (jsn.Testonomials != null) {
    //                 let parsedArray = JSON.parse(jsn.Testonomials);
    //                 setTestData(parsedArray);
    //             }
    //             if (jsn.Home != null) {
    //                 if (JSON.parse(jsn.Home).length) {
    //                     setHomeData(JSON.parse(jsn.Home)[0]);
    //                 }
    //             }
    //             if (jsn.Counts != null) {
    //                 let parsedArray = JSON.parse(jsn.Counts);
    //                 setCountData(parsedArray);
    //             }
    //             if (jsn.Events != null) {
    //                 let parsedArray = JSON.parse(jsn.Events);
    //                 setEventData(parsedArray);
    //             }
    //         }
    //     }
    //     makeRequest();
    // }, [props.data]);

    useEffect(() => {
        setPhoneNo(aboutData.phoneNo);
    }, [slideData]);

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }).catch(() => { return false; });
    }

    function remove_n_words(words, n) {
        if (words != null) {
            return n === 0 ? words : words.split(" ").slice(0, -n).join(" ");
        }
    }

    function getLastNWords(str, n) {
        if (str != null) {
            const words = str.split(' ');
            if (words.length < n) {
                return str; // Return the original string if there are fewer than 2 words
            }
            return words.slice(-n).join(' ');
        }
    }

    return (
        <Fragment>
            {/* start slider */}
            <section id="slider" className="p-0 top-space-margin">
                <div className="demo-corporate-slider_wrapper fullscreen-container" data-alias="portfolio-viewer" data-source="gallery" style={{ backgroundColor: "transparent", padding: "0px" }}>
                    <div id="demo-corporate-slider" className="rev_slider bg-regal-blue fullscreenbanner" style={{ display: "none" }} data-version="5.3.1.6">
                        {/* begin slides list */}
                        <ul>
                            {/* minimum slide structure */}
                            <li data-index="rs-01" data-transition="parallaxleft" data-slotamount="default"
                                data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                                data-easeout="default" data-masterspeed="1500" data-rotate="0" data-saveperformance="off"
                                data-title="Crossfit" data-param1="" data-param2="" data-param3="" data-param4=""
                                data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
                                data-description="">
                                {/* slide's main background image */}
                                <img src="assets/images/banner/banner_guru.jpeg" alt="Image"
                                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                    className="rev-slidebg" data-no-retina />
                                {/* start overlay layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper " id="slide-1-layer-01"
                                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                    data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                    data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                    data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},
                                     {"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power4.easeInOut"}]' style={{ background: "rgba(22,35,63,0.1)", zIndex: 0 }}>
                                </div>
                                {/* end overlay layer */}
                                {/* start shape layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-1-layer-02" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['900','700','700','600']"
                                    data-height="['900','700','700','600']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1000,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.5;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    ////data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end shape layer */}
                                {/* start shape layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-1-layer-03" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['1200','1000','900','800']"
                                    data-height="['1200','1000','900','800']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1300,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.3;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    ////data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end shape layer */}
                                {/* start row zone layer */}
                                <div id="rrzm_638" className="rev_row_zone rev_row_zone_middle">
                                    {/* start row layer */}
                                    <div className="tp-caption  " id="slide-1-layer-04" data-x="['left','left','left','left']"
                                        data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                        data-voffset="['-426','-426','-426','-426']" data-width="none" data-height="none"
                                        data-whitespace="nowrap" data-type="row" data-columnbreak="3"
                                        data-responsive_offset="on" data-responsive="off"
                                        data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                        ////data-textAlign="['inherit','inherit','inherit','inherit']"
                                        data-paddingtop="[0,0,0,0]" data-paddingright="[100,75,50,30]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[100,75,50,30]">
                                        {/* start column layer */}
                                        <div className="tp-caption" id="slide-1-layer-05" data-x="['left','left','left','left']"
                                            data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']"
                                            data-voffset="['100','100','100','100']" data-width="none" data-height="none"
                                            data-whitespace="nowrap" data-type="column" data-responsive_offset="on"
                                            data-responsive="off"
                                            data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                            data-columnwidth="100%" data-verticalalign="top"
                                        ////data-textAlign="['center','center','center','center']"
                                        >
                                            {/* start subtitle layer */}
                                            <div className="tp-caption mx-auto text-uppercase" id="slide-1-layer-06"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['13','13','13','13']"
                                                data-lineheight="['20','20','20','20']"
                                                data-fontweight="['500','500','500','500']"
                                                data-letterspacing="['1','1','1','1']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['800','auto','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="off"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:-50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[25,25,10,10]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial" }}>
                                                <i className="bi bi-award pe-5px icon-small"></i>Aumyog - साधना से समृद्धि की ओर
                                            </div>
                                            {/* end subtitle layer */}
                                            {/* start title layer */}
                                            <div className="tp-caption mx-auto" id="slide-1-layer-07"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['75','60','70','50']"
                                                data-lineheight="['70','65','75','55']"
                                                data-fontweight="['700','700','700','700']"
                                                data-letterspacing="['-2','-2','-2','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['700','600','600','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":"1500","split":"chars","splitdelay":0.03,"speed":800,"split_direction":"middletoedge","frame":"0","from":"x:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":100,"frame":"999","to":"opacity:0;fb:0;","ease":"Power4.easeOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[33,28,35,25]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial", textShadow: "#0b1236 3px 3px 15px" }}>
                                                Understanding Mantra Shastra: The Science of Sound and Vibration
                                            </div>
                                            {/* end title layer */}
                                            {/* start text layer */}
                                            <div className="tp-caption mx-auto" id="slide-1-layer-08"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['20','20','24','20']"
                                                data-lineheight="['36','36','40','30']"
                                                data-fontweight="['300','300','300','300']"
                                                data-letterspacing="['0','0','0','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['500','500','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:0.6;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[36,36,60,40]" data-paddingleft="[0,0,0,0]">
                                                Mantra Shastra explores the transformative power of sound and vibration.
                                            </div>
                                            {/* end text layer */}
                                            {/* start button layer */}
                                            <div className="tp-caption tp-resizeme" id="slide-1-layer-09"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']"
                                                data-voffset="['0','0','0','0']" data-width="auto" data-height="none"
                                                data-whitespace="nowrap" data-fontsize="['18','16','16','16']"
                                                data-lineheight="['70','55','55','55']" data-type="text"
                                                data-responsive_offset="off" data-responsive="off"
                                                data-frames='[{"delay":3000,"speed":1000,"frame":"0","from":"y:100px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                //data-textAlign="['inherit','inherit','inherit','inherit']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[75,70,65,60]"
                                                data-paddingbottom="[0,0,0,0]" data-paddingleft="[45,35,30,30]">
                                                <a href="contact"
                                                    className="btn btn-extra-large get-started-btn btn-rounded with-rounded btn-gradient-flamingo-amethyst-green btn-box-shadow">Get
                                                    started now<span className="bg-white text-base-color"><i
                                                        className="fa-solid fa-arrow-right"></i></span></a>
                                            </div>
                                            {/* end button layer */}
                                        </div>
                                        {/* end column layer */}
                                    </div>
                                    {/* end row layer */}
                                </div>
                                {/* end row zone layer */}
                                {/* start beige background layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-base-color border-radius-50"
                                    id="slide-1-layer-10" data-x="['center','center','center','center']"
                                    data-hoffset="['510','410','310','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['-320','-250','-250','0']" data-width="['122','122','120','120']"
                                    data-height="['122','122','120','120']" data-visibility="['on','on','off','off']"
                                    data-whitespace="nowrap" data-basealign="grid" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":3500,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end beige background layer */}
                                {/* start beige text layer */}
                                <div className="tp-caption d-inline-block" id="slide-1-layer-11"
                                    data-x="['center','center','center','center']" data-hoffset="['510','410','310','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-305','-250','-250','0']"
                                    data-visibility="['on','on','off','off']" data-fontsize="['13','13','13','13']"
                                    data-lineheight="['16','16','16','16']" data-fontweight="['500','600','600','600']"
                                    data-letterspacing="['0','0','0','0']"
                                    data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                    data-width="['100','100','100','100']" data-height="auto" data-whitespace="normal"
                                    data-basealign="grid" data-type="text" data-responsive_offset="on"
                                    data-verticalalign="middle" data-responsive="on"
                                    data-frames='[{"delay":3700,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[33,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ wordBreak: "initial" }}>
                                    <i className="bi bi-patch-check-fill icon-extra-medium d-block pb-10px"></i> <span
                                        className="d-block text-uppercase"></span>
                                </div>
                                {/* end beige text layer */}
                            </li>
                            <li data-index="rs-02" data-transition="parallaxleft" data-slotamount="default"
                                data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                                data-easeout="default" data-masterspeed="1500" data-rotate="0" data-saveperformance="off"
                                data-title="Crossfit" data-param1="" data-param2="" data-param3="" data-param4=""
                                data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
                                data-description="">
                                {/* slide's main background image */}
                                <img src="assets/images/banner/banner_sadhak.jpeg" alt="Image"
                                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                    className="rev-slidebg" data-no-retina />
                                {/* start overlay layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper " id="slide-2-layer-01"
                                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                    data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                    data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                    data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},
                                     {"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power4.easeInOut"}]' style={{ background: "rgba(22,35,63,0.1)", zIndex: 0 }}>
                                </div>
                                {/* end overlay layer */}
                                {/* start shape layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-2-layer-02" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['900','700','700','600']"
                                    data-height="['900','700','700','600']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1000,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.5;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end shape layer */}
                                {/* start shape layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-2-layer-03" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['1200','1000','900','800']"
                                    data-height="['1200','1000','900','800']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1300,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.3;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end shape layer */}
                                {/* start row zone layer */}
                                <div id="rrzm_639" className="rev_row_zone rev_row_zone_middle">
                                    {/* start row layer */}
                                    <div className="tp-caption  " id="slide-2-layer-04" data-x="['left','left','left','left']"
                                        data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                        data-voffset="['-426','-426','-426','-426']" data-width="none" data-height="none"
                                        data-whitespace="nowrap" data-type="row" data-columnbreak="3"
                                        data-responsive_offset="on" data-responsive="off"
                                        data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                        //data-textAlign="['inherit','inherit','inherit','inherit']"
                                        data-paddingtop="[0,0,0,0]" data-paddingright="[100,75,50,30]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[100,75,50,30]">
                                        {/* start column layer */}
                                        <div className="tp-caption" id="slide-2-layer-05" data-x="['left','left','left','left']"
                                            data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']"
                                            data-voffset="['100','100','100','100']" data-width="none" data-height="none"
                                            data-whitespace="nowrap" data-type="column" data-responsive_offset="on"
                                            data-responsive="off"
                                            data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                            data-columnwidth="100%" data-verticalalign="top"
                                        //data-textAlign="['center','center','center','center']"
                                        >
                                            {/* start subtitle layer */}
                                            <div className="tp-caption mx-auto text-uppercase" id="slide-2-layer-06"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['13','13','13','13']"
                                                data-lineheight="['20','20','20','20']"
                                                data-fontweight="['500','500','500','500']"
                                                data-letterspacing="['1','1','1','1']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['800','auto','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="off"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:-50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[25,25,10,10]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial" }}>
                                                <i className="bi bi-award pe-5px icon-small"></i>Aumyog - साधना से समृद्धि की ओर
                                            </div>
                                            {/* end subtitle layer */}
                                            {/* start title layer */}
                                            <div className="tp-caption mx-auto" id="slide-2-layer-07"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['75','60','70','50']"
                                                data-lineheight="['70','65','75','55']"
                                                data-fontweight="['700','700','700','700']"
                                                data-letterspacing="['-2','-2','-2','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['700','600','600','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":"1500","split":"chars","splitdelay":0.03,"speed":800,"split_direction":"middletoedge","frame":"0","from":"x:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":100,"frame":"999","to":"opacity:0;fb:0;","ease":"Power4.easeOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[33,28,35,25]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial", textShadow: "#0b1236 3px 3px 15px" }}>
                                                Mantra Shastra: Ancient Wisdom for Modern Life
                                            </div>
                                            {/* end title layer */}
                                            {/* start text layer */}
                                            <div className="tp-caption mx-auto" id="slide-2-layer-08"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['20','20','24','20']"
                                                data-lineheight="['36','36','40','30']"
                                                data-fontweight="['300','300','300','300']"
                                                data-letterspacing="['0','0','0','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['500','500','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:0.6;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[36,36,60,40]" data-paddingleft="[0,0,0,0]">
                                                Each mantra carries unique energy, unlocking spiritual and emotional growth.
                                            </div>
                                            {/* end text layer */}
                                            {/* start button layer */}
                                            <div className="tp-caption tp-resizeme" id="slide-2-layer-09"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']"
                                                data-voffset="['0','0','0','0']" data-width="auto" data-height="none"
                                                data-whitespace="nowrap" data-fontsize="['18','16','16','16']"
                                                data-lineheight="['70','55','55','55']" data-type="text"
                                                data-responsive_offset="off" data-responsive="off"
                                                data-frames='[{"delay":3000,"speed":1000,"frame":"0","from":"y:100px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                //data-textAlign="['inherit','inherit','inherit','inherit']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[75,70,65,60]"
                                                data-paddingbottom="[0,0,0,0]" data-paddingleft="[45,35,30,30]">
                                                <a href="#aboutus"
                                                    className="btn btn-extra-large get-started-btn btn-rounded with-rounded btn-gradient-flamingo-amethyst-green btn-box-shadow">Get
                                                    started now<span className="bg-white text-base-color"><i
                                                        className="fa-solid fa-arrow-right"></i></span></a>
                                            </div>
                                            {/* end button layer */}
                                        </div>
                                        {/* end column layer */}
                                    </div>
                                    {/* end row layer */}
                                </div>
                                {/* end row zone layer */}
                                {/* start beige background layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-base-color border-radius-50"
                                    id="slide-2-layer-10" data-x="['center','center','center','center']"
                                    data-hoffset="['510','410','310','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['-320','-250','-250','0']" data-width="['122','122','120','120']"
                                    data-height="['122','122','120','120']" data-visibility="['on','on','off','off']"
                                    data-whitespace="nowrap" data-basealign="grid" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":3500,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    ////data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end beige background layer */}
                                {/* start beige text layer */}
                                <div className="tp-caption d-inline-block" id="slide-2-layer-11"
                                    data-x="['center','center','center','center']" data-hoffset="['510','410','310','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-305','-250','-250','0']"
                                    data-visibility="['on','on','off','off']" data-fontsize="['13','13','13','13']"
                                    data-lineheight="['16','16','16','16']" data-fontweight="['500','600','600','600']"
                                    data-letterspacing="['0','0','0','0']"
                                    data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                    data-width="['100','100','100','100']" data-height="auto" data-whitespace="normal"
                                    data-basealign="grid" data-type="text" data-responsive_offset="on"
                                    data-verticalalign="middle" data-responsive="on"
                                    data-frames='[{"delay":3700,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[33,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ wordBreak: "initial" }}>
                                    <i className="bi bi-patch-check-fill icon-extra-medium d-block pb-10px"></i> <span
                                        className="d-block text-uppercase"></span>
                                </div>
                                {/* end beige text layer */}
                            </li>
                            <li data-index="rs-03" data-transition="parallaxleft" data-slotamount="default"
                                data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                                data-easeout="default" data-masterspeed="1500" data-rotate="0" data-saveperformance="off"
                                data-title="Crossfit" data-param1="" data-param2="" data-param3="" data-param4=""
                                data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
                                data-description="">
                                {/* slide's main background image */}
                                <img src="assets/images/banner/banner_persistant.jpeg" alt="Image"
                                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                    className="rev-slidebg" data-no-retina />
                                {/* start overlay layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper " id="slide-3-layer-01"
                                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                    data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                    data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                    data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},
                                     {"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power4.easeInOut"}]' style={{ background: "rgba(22,35,63,0.1)", zIndex: 0 }}>
                                </div>
                                {/* end overlay layer */}
                                {/* start shape layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-3-layer-02" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['900','700','700','600']"
                                    data-height="['900','700','700','600']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1000,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.5;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end shape layer */}
                                {/* start shape layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-3-layer-03" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['1200','1000','900','800']"
                                    data-height="['1200','1000','900','800']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1300,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.3;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end shape layer */}
                                {/* start row zone layer */}
                                <div id="rrzm_640" className="rev_row_zone rev_row_zone_middle">
                                    {/* start row layer */}
                                    <div className="tp-caption  " id="slide-3-layer-04" data-x="['left','left','left','left']"
                                        data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                        data-voffset="['-426','-426','-426','-426']" data-width="none" data-height="none"
                                        data-whitespace="nowrap" data-type="row" data-columnbreak="3"
                                        data-responsive_offset="on" data-responsive="off"
                                        data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                        //data-textAlign="['inherit','inherit','inherit','inherit']"
                                        data-paddingtop="[0,0,0,0]" data-paddingright="[100,75,50,30]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[100,75,50,30]">
                                        {/* start column layer */}
                                        <div className="tp-caption" id="slide-3-layer-05" data-x="['left','left','left','left']"
                                            data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']"
                                            data-voffset="['100','100','100','100']" data-width="none" data-height="none"
                                            data-whitespace="nowrap" data-type="column" data-responsive_offset="on"
                                            data-responsive="off"
                                            data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                            data-columnwidth="100%" data-verticalalign="top"
                                        //data-textAlign="['center','center','center','center']"
                                        >
                                            {/* start subtitle layer */}
                                            <div className="tp-caption mx-auto text-uppercase" id="slide-3-layer-06"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['13','13','13','13']"
                                                data-lineheight="['20','20','20','20']"
                                                data-fontweight="['500','500','500','500']"
                                                data-letterspacing="['1','1','1','1']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['800','auto','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="off"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:-50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[25,25,10,10]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial" }}>
                                                <i className="bi bi-award pe-5px icon-small"></i>Aumyog - साधना से समृद्धि की ओर
                                            </div>
                                            {/* end subtitle layer */}
                                            {/* start title layer */}
                                            <div className="tp-caption mx-auto" id="slide-3-layer-07"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['75','60','70','50']"
                                                data-lineheight="['70','65','75','55']"
                                                data-fontweight="['700','700','700','700']"
                                                data-letterspacing="['-2','-2','-2','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['700','600','600','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":"1500","split":"chars","splitdelay":0.03,"speed":800,"split_direction":"middletoedge","frame":"0","from":"x:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":100,"frame":"999","to":"opacity:0;fb:0;","ease":"Power4.easeOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[33,28,35,25]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial", textShadow: "#0b1236 3px 3px 15px" }}>
                                                A Comprehensive Guide to Sacred Chants and Their Benefits
                                            </div>
                                            {/* end title layer */}
                                            {/* start text layer */}
                                            <div className="tp-caption mx-auto" id="slide-3-layer-08"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['20','20','24','20']"
                                                data-lineheight="['36','36','40','30']"
                                                data-fontweight="['300','300','300','300']"
                                                data-letterspacing="['0','0','0','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['500','500','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:0.6;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                //data-textAlign="['center','center','center','center']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[36,36,60,40]" data-paddingleft="[0,0,0,0]">
                                                Practicing mantra meditation enhances focus, peace, and inner clarity.
                                            </div>
                                            {/* end text layer */}
                                            {/* start button layer */}
                                            <div className="tp-caption tp-resizeme" id="slide-3-layer-09"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']"
                                                data-voffset="['0','0','0','0']" data-width="auto" data-height="none"
                                                data-whitespace="nowrap" data-fontsize="['18','16','16','16']"
                                                data-lineheight="['70','55','55','55']" data-type="text"
                                                data-responsive_offset="off" data-responsive="off"
                                                data-frames='[{"delay":3000,"speed":1000,"frame":"0","from":"y:100px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                //data-textAlign="['inherit','inherit','inherit','inherit']"
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[75,70,65,60]"
                                                data-paddingbottom="[0,0,0,0]" data-paddingleft="[45,35,30,30]">
                                                <a href="#aboutus"
                                                    className="btn btn-extra-large get-started-btn btn-rounded with-rounded btn-gradient-flamingo-amethyst-green btn-box-shadow">Get
                                                    started now<span className="bg-white text-base-color"><i
                                                        className="fa-solid fa-arrow-right"></i></span></a>
                                            </div>
                                            {/* end button layer */}
                                        </div>
                                        {/* end column layer */}
                                    </div>
                                    {/* end row layer */}
                                </div>
                                {/* end row zone layer */}
                                {/* start beige background layer */}
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-base-color border-radius-50"
                                    id="slide-3-layer-10" data-x="['center','center','center','center']"
                                    data-hoffset="['510','410','310','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['-320','-250','-250','0']" data-width="['122','122','120','120']"
                                    data-height="['122','122','120','120']" data-visibility="['on','on','off','off']"
                                    data-whitespace="nowrap" data-basealign="grid" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":3500,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                {/* end beige background layer */}
                                {/* start beige text layer */}
                                <div className="tp-caption d-inline-block" id="slide-3-layer-11"
                                    data-x="['center','center','center','center']" data-hoffset="['510','410','310','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-305','-250','-250','0']"
                                    data-visibility="['on','on','off','off']" data-fontsize="['13','13','13','13']"
                                    data-lineheight="['16','16','16','16']" data-fontweight="['500','600','600','600']"
                                    data-letterspacing="['0','0','0','0']"
                                    data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                    data-width="['100','100','100','100']" data-height="auto" data-whitespace="normal"
                                    data-basealign="grid" data-type="text" data-responsive_offset="on"
                                    data-verticalalign="middle" data-responsive="on"
                                    data-frames='[{"delay":3700,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    //data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[33,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ wordBreak: "initial" }}>
                                    <i className="bi bi-patch-check-fill icon-extra-medium d-block pb-10px"></i> <span
                                        className="d-block text-uppercase"></span>
                                </div>
                                {/* end beige text layer */}
                            </li>
                        </ul>
                        <div className="tp-bannertimer"
                            style={{ height: "10px", backgroundColor: "rgba(0, 0, 0, 0.10)", zIndex: 98 }}></div>
                    </div>
                </div>
            </section>
            {/* end slider */}
            {/* start section */}
            <section className="p-0 border-bottom border-color-extra-medium-gray">
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-3 row-cols-sm-2 justify-content-center" data-anime>
                        {/* start content box item */}
                        <div
                            className="col pt-35px pb-35px md-pb-0 text-dark-gray border-end border-color-extra-medium-gray sm-border-end-0">
                            <div
                                className="d-flex flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-start">
                                <div className="flex-shrink-0 me-15px md-me-0">
                                    <h2 className="mb-0 fw-800">99<sup className="fs-24">%</sup></h2>
                                </div>
                                <div>
                                    <span className="lh-24 fw-600 d-block">Problems <br />Solved</span>
                                </div>
                            </div>
                        </div>
                        {/* end content box item */}
                        {/* start content box item */}
                        <div
                            className="col pt-35px pb-35px md-pb-0 text-dark-gray border-end border-color-extra-medium-gray sm-border-end-0">
                            <div
                                className="d-flex flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-start">
                                <div className="flex-shrink-0 me-15px md-me-0">
                                    <h2 className="mb-0 fw-800 ls-minus-3px">4.98</h2>
                                </div>
                                <div>
                                    <div className="review-star-icon fs-20 d-inline-block text-gradient-orange-sky-blue">
                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                    <span className="lh-24 fw-600 d-block">Best rated by sadhak</span>
                                </div>
                            </div>
                        </div>
                        {/* end content box item */}
                        {/* start content box item */}
                        <div className="col pt-35px pb-35px text-dark-gray">
                            <div
                                className="d-flex flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-start">
                                <div className="flex-shrink-0 me-15px md-me-0">
                                    <h2 className="mb-0 fw-800">98<sup className="fs-24">%</sup></h2>
                                </div>
                                <div>
                                    <span className="lh-24 fw-600 d-block">Genuine repeated <br />happy Sadhak.</span>
                                </div>
                            </div>
                        </div>
                        {/* end content box item */}
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            <section id="aboutus">
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-6 sm-pb-9">
                        <div className="col-lg-6 col-md-9 position-relative md-mb-15 text-center text-lg-start"
                            data-anime-about>
                            <div className="absolute-middle-center z-index-9 counter-style-02 text-center">
                                <span className="fs-160 fw-800 text-dark-gray ls-minus-10px xs-ls-minus-5px position-relative lg-fs-130 xs-fs-75">40<sub className="align-top fs-80 lg-fs-70 text-dark-gray position-relative top-minus-3px">+</sub></span>
                                <span className="d-block mx-auto fs-20 fw-500 lh-26 w-70 text-center text-dark-gray xs-w-100">Years in guiding people in Mantra, Tantra and Yantra</span>
                            </div>
                            <img src="assets/images/demo-corporate-03.png" alt="" />
                            <img src="assets/images/self/40_years.jpeg" className="position-absolute top-50 left-minus-100px lg-left-minus-40px sm-left-minus-30px lg-w-50 sm-w-55" data-bottom-top="transform: translateY(50px)" data-top-bottom="transform: translateY(-220px)" alt="" />
                            <img src="assets/images/logo/aumyog_logo_trans.png" className="position-absolute top-0px xl-top-minus-10px w-170px right-20px md-right-40px xs-w-40" data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)" alt="" />
                        </div>
                        <div className="col-lg-6 ps-6 text-center text-lg-start lg-ps-15px" data-anime-about-1>
                            <span className="ps-25px pe-25px mb-20px text-uppercase text-base-color fs-14 lh-42px fw-700 border-radius-100px bg-gradient-very-light-gray-transparent d-inline-block">About Us</span>
                            <h3 className="text-dark-gray fw-700 ls-minus-1px">Learn Mantra, Tantra, Yantra effects in our life from expert</h3>
                            <p className="w-80 xl-w-90 lg-w-100 mb-40px sm-mb-25px">Life-changing guidance by Gurushree in his “AUMYog Karyashala” in Mind-blowing, straight forward style.</p>
                            <a href="about.html" className="btn btn-large btn-dark-gray btn-hover-animation-switch btn-box-shadow btn-rounded me-25px xs-me-0">
                                <span>
                                    <span className="btn-text">Read about us</span>
                                    <span className="btn-icon"><i className="feather icon-feather-arrow-right"></i></span>
                                    <span className="btn-icon"><i className="feather icon-feather-arrow-right"></i></span>
                                </span>
                            </a>
                            <span className="text-dark-gray fw-700 ls-minus-05px d-block d-sm-inline-block sm-mt-15px"><a href="tel:919922371107"><i className="feather icon-feather-phone-call me-10px"></i>+91 99223 71107</a></span>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 justify-content-center counter-style-07 ps-3 pe-3" data-anime-count>
                        {/* start counter item */}
                        <div className="col text-center sm-mb-30px">
                            <h2 className="vertical-counter d-inline-flex text-dark-gray fw-800 mb-0 ls-minus-3px position-relative z-index-0" data-to="">4586
                                <span className="text-highlight position-absolute bottom-9px w-100"><span className="bg-gradient-flamingo-amethyst-green h-10px opacity-2"></span></span>
                            </h2>
                            <span className="d-block fs-14 fw-700 text-uppercase text-dark-gray">Telephonic talk</span>
                        </div>
                        {/* end counter item */}
                        {/* start counter item */}
                        <div className="col text-center sm-mb-30px">
                            <h2 className="vertical-counter d-inline-flex text-dark-gray fw-800 mb-0 ls-minus-3px position-relative z-index-0" data-to="583">583
                                <span className="text-highlight position-absolute bottom-9px w-100"><span className="bg-gradient-flamingo-amethyst-green h-10px opacity-2"></span></span>
                            </h2>
                            <span className="d-block fs-14 fw-700 text-uppercase text-dark-gray">Cases solved</span>
                        </div>
                        {/* end counter item */}
                        {/* start counter item */}
                        <div className="col text-center sm-mb-30px">
                            <h2 className="vertical-counter d-inline-flex text-dark-gray fw-800 mb-0 ls-minus-3px position-relative z-index-0" data-to="">6548
                                <span className="text-highlight position-absolute bottom-9px w-100"><span className="bg-gradient-flamingo-amethyst-green h-10px opacity-2"></span></span>
                            </h2>
                            <span className="d-block fs-14 fw-700 text-uppercase text-dark-gray">Events</span>
                        </div>
                        {/* end counter item */}
                        {/* start counter item */}
                        <div className="col text-center">
                            <h2 className="vertical-counter d-inline-flex text-dark-gray fw-800 mb-0 ls-minus-3px position-relative z-index-0" data-to="836">836
                                <span className="text-highlight position-absolute bottom-9px w-100"><span className="bg-gradient-flamingo-amethyst-green h-10px opacity-2"></span></span>
                            </h2>
                            <span className="d-block fs-14 fw-700 text-uppercase text-dark-gray">Happy Sadhak</span>
                        </div>
                        {/* end counter item */}
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            <section className="overflow-hidden bg-regal-blue position-relative border-radius-6px lg-border-radius-0px z-index-0">
                <img src="assets/images/others/demo-corporate-bg-01.png" className="position-absolute top-minus-150px left-minus-30px z-index-minus-1" data-bottom-top="transform: rotate(0deg) translateY(0)" data-top-bottom="transform:rotate(-20deg) translateY(0)" alt="" />
                <div className="container">
                    <div className="row align-items-center mb-6 sm-mb-9 text-center text-lg-start">
                        <div className="col-lg-5 md-mb-20px">
                            <span className="ps-25px pe-25px mb-10px text-uppercase text-white fs-13 lh-42px fw-600 border-radius-100px bg-gradient-blue-whale-transparent d-inline-block">Events</span>
                            <h3 className="text-white fw-700 mb-0 ls-minus-1px">Our Events</h3>
                        </div>
                        <div className="col-lg-5 last-paragraph-no-margin md-mb-20px">
                            <p className="w-85 md-w-100">Our events are bundle with devotional energy of mantras and tantras.</p>
                        </div>
                        <div className="col-lg-2 d-flex justify-content-center justify-content-lg-end">
                            {/* start slider navigation */}
                            <div className="slider-one-slide-prev-1 icon-extra-medium text-white swiper-button-prev slider-navigation-style-04 border border-1 border-color-transparent-white-light">
                                <i className="feather icon-feather-chevron-left"></i>
                            </div>
                            <div className="slider-one-slide-next-1 icon-extra-medium text-white swiper-button-next slider-navigation-style-04 border border-1 border-color-transparent-white-light">
                                <i className="feather icon-feather-chevron-right"></i>
                            </div>
                            {/* end slider navigation */}
                        </div>
                    </div>

                    <div className="row align-items-center mb-6">
                        <div className="col-12">
                            <div className="outside-box-right-25 sm-outside-box-right-0">
                                <div className="swiper magic-cursor slider-one-slide">
                                    <Slider className="swiper magic-cursor slider-one-slide" {...settings}>
                                        {
                                            data.map((d, index) =>
                                            (
                                                <div key={index} className="swiper-slide">
                                                    {/* start interactive banner item */}
                                                    <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                                        <img src={d.image} alt="" />
                                                        <div className="opacity-extra-medium bg-gradient-dark-transparent"></div>
                                                        <div className="image-content h-100 w-100 ps-15 pe-15 pt-13 pb-13 md-p-10 d-flex justify-content-bottom align-items-start flex-column">
                                                            <div className="hover-label-icon position-relative z-index-9">
                                                                <div className="label bg-base-color fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12 ls-05px">{d.label}</div>
                                                                {/* <i className="line-icon-Medal-2 text-white icon-extra-large"></i> */}
                                                            </div>
                                                            <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                                <span className="text-white fw-600 fs-20">{d.text}</span>
                                                                <span className="content-title text-white fs-13 fw-500 text-uppercase ls-05px">{d.title}</span>
                                                                <a href="demo-corporate-services-details.html" className="content-title-hover fs-13 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom">Explore More</a>
                                                                <span className="content-arrow lh-42px rounded-circle bg-white w-50px h-50px ms-20px text-center"><i className="fa-solid fa-chevron-right text-dark-gray fs-16"></i></span>
                                                            </div>
                                                            <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9">
                                                            </div>
                                                            <div className="box-overlay bg-gradient-base-color-transparent"></div>
                                                            <a href="demo-corporate-services-details.html" className="position-absolute z-index-1 top-0px left-0px h-100 w-100"></a>
                                                        </div>
                                                    </div>
                                                    {/* end interactive banner item */}
                                                </div>
                                            ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            <section>
                <div className="container position-relative">
                    <div className="row align-items-center mb-7">
                        <div className="col-xxl-4 col-lg-5 md-mb-15 sm-mb-20 text-center text-lg-start">
                            <span className="ps-25px pe-25px mb-20px text-uppercase text-base-color fs-14 lh-42px fw-700 border-radius-100px bg-gradient-very-light-gray-transparent d-inline-block">Simple process</span>
                            <h3 className="text-dark-gray fw-700 ls-minus-2px mb-40px">For Personal Mantra</h3>
                            <div className="row row-cols-1" data-anime-step>
                                {/* start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-60px w-60px fs-14 bg-light-red fw-700 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">01</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                            <span className="progress-step-separator bg-dark-gray opacity-1"></span>
                                        </div>
                                        <div className="process-content ps-30px last-paragraph-no-margin mb-30px">
                                            <span className="d-block fw-700 text-dark-gray mb-5px fs-18">Contact Us</span>
                                            <p className="w-90 lg-w-100 lh-32">Contacts us on call, sms or whats app</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end process step item */}
                                {/* start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-60px w-60px fs-14 bg-light-red fw-700 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">02</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                            <span className="progress-step-separator bg-dark-gray opacity-1"></span>
                                        </div>
                                        <div className="process-content ps-30px last-paragraph-no-margin mb-30px">
                                            <span className="d-block fw-700 text-dark-gray mb-5px fs-18">Discussion the problem</span>
                                            <p className="w-90 lg-w-100 lh-32">Let us know your problem.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end process step item */}
                                {/* start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box xs-mb-30px">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-60px w-60px fs-14 bg-light-red fw-700 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">03</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                        </div>
                                        <div className="process-content ps-30px last-paragraph-no-margin">
                                            <span className="d-block fw-700 text-dark-gray mb-5px fs-18">Customized Mantra</span>
                                            <p className="w-90 lg-w-100 lh-32">We will give you mantra as per your problem.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end process step item */}
                            </div>
                        </div>
                        <div className="col-lg-7 offset-xxl-1 position-relative md-mb-30px sm-mb-15"
                            data-anime-1>
                            <img src="assets/images/self/personalized_mantra.jpeg" className="position-relative z-index-9 top-40px" alt="" />
                            {/* <img src="assets/images/demo-corporate-03.png" className="absolute-middle-center xs-w-95" alt="">
                            <img src="https://via.placeholder.com/144x64" className="position-absolute top-50px left-0px xs-left-15px"
                                 data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)"
                                 alt="">
                            <img src="https://via.placeholder.com/67x34" className="position-absolute top-150px left-30"
                                 data-bottom-top="transform: translateY(30px)" data-top-bottom="transform: translateY(-30px)"
                                 alt="">
                            <img src="https://via.placeholder.com/61x30" className="position-absolute top-50px right-30"
                                 data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)"
                                 alt="">
                            <img src="https://via.placeholder.com/93x45"
                                 className="position-absolute top-100px right-0px xs-right-15px"
                                 data-bottom-top="transform: translateY(30px)" data-top-bottom="transform: translateY(-30px)"
                                 alt=""> */}
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 text-center align-items-center" data-anime-2>
                            <div className="bg-base-color fw-700 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12 me-10px sm-m-5px d-inline-block align-middle">hurray</div>
                            <div className="fs-18 fw-500 text-dark-gray d-inline-block align-middle">Get the solution on your problem. <a href="demo-corporate-contact.html" className="text-dark-gray text-decoration-line-bottom fw-700">Contact Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            <section className="big-section position-relative">
                <div id="particles-01" data-particle="true" data-particle-options='{"particles": {"number": {"value": 10,"density": {"enable": true,"value_area": 1000}},"color":{"value":["#ff5b74", "#820f89"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.7,"random": false,"anim": {"enable": false,"speed": 2,"sync": false}},"size": {"value": 7,"random": true,"anim": {"enable": false,"sync": true}},"move": {"enable": true,"speed":2,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}' className="position-absolute top-0px left-0px w-100 z-index-minus-1"></div>
                <div className="container">
                    <div className="row justify-content-center overlap-section mb-6 g-0">
                        <div className="col-auto text-center last-paragraph-no-margin bg-white pt-20px pb-20px ps-6 pe-6 border-radius-100px" data-anime-3>
                            <div className="text-center bg-golden-yellow text-white fs-16 lh-36 border-radius-30px d-inline-block ps-20px pe-20px align-middle me-10px">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                            </div>
                            <div className="d-inline-block fs-18 text-dark-gray fs-18 align-middle fw-500"><span className="text-decoration-line-bottom-medium fw-600">25,000+ happy sadhak </span> visited and took advice in their life problems.</div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xxl-7 col-xl-8 col-lg-10 col-md-11 text-center">
                            <h1 className="fw-700 text-dark-gray ls-minus-1px">Loved by most valuable sadhaks</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center" data-anime-4>
                            <div className="swiper swiper-horizontal-3d lg-pt-10 lg-pb-10 md-pt-12 sm-pt-15 sm-pb-11 swiper-pagination-bottom">
                                    <Slider className="swiper magic-cursor slider-one-slide" {...testoSlides}>
                                        {
                                            dataTesto.map((d, index) =>
                                            (
                                                <div key={index} className="swiper-slide bg-white border-radius-4px">
                                                    <div className="position-relative ps-13 pe-13 md-ps-10 md-pe-10 sm-ps-7 sm-pe-7 pt-20 pb-10 lg-pt-22 md-pt-30 sm-pt-20">
                                                        {/* <img alt="" src="https://via.placeholder.com/148x148" className="absolute-middle-center top-0px rounded-circle w-150px xs-w-100px border border-color-white box-shadow-extra-large border-8" /> */}
                                                        <div className="testimonials-content">
                                                            {/* <span className="text-dark-gray fs-18 lh-30 fw-600 mb-5px d-block">Just love their design for all stunning details!</span> */}
                                                            <p className="mb-25px">{d.message}</p>
                                                        </div>
                                                        <div className="testimonials-author fs-18 mb-5px text-gradient-fast-blue-purple fw-600 d-inline-block">{d.name}</div>
                                                        <div className="testimonials-position fs-15 lh-20">{d.work}</div>
                                                    </div>
                                                </div>
                                            ))}
                                    </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            <section className="p-0">
                <div className="container">
                    <div className="row justify-content-center" data-anime-5>
                        <div className="col-12">
                            <div className="border-radius-6px h-450px md-h-350px sm-h-400px d-flex flex-wrap align-items-center justify-content-center overflow-hidden cover-background box-shadow-quadruple-large pt-15" style={{ backgroundImage: "url(assets/images/self/video.jpeg)" }}>
                                <div className="opacity-full-dark bg-gradient-regal-blue-transparent"></div>
                                <div className="row justify-content-center m-0">
                                    <div className="col-lg-7 col-md-8 z-index-1 text-center text-md-start sm-mb-20px">
                                        <h3 className="text-white mb-0 fw-400 fancy-text-style-4">Watch this introductionary video to resolve <span className="fw-600" data-fancy-text='{ "effect": "rotate", "string": ["Adversity!", "Personal Problems!", "in Business!"] }'></span>
                                        </h3>
                                    </div>
                                    <div className="col-md-2 position-relative z-index-1 text-center sm-mb-20px">
                                        <a href="https://www.youtube.com/watch?v=nEThHbDyvfE" className="position-relative d-inline-block text-center border border-2 border-color-white rounded-circle video-icon-box video-icon-large popup-youtube">
                                            <span>
                                                <span className="video-icon">
                                                    <i className="fa-solid fa-play fs-20 text-white"></i>
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="w-100 text-center position-relative mt-auto pt-20px pb-25px ps-15px pe-15px border-top border-color-transparent-white-light">
                                    <div className="fs-14 text-uppercase text-white fw-600 ls-05px"><a href="demo-corporate-contact.html" className="text-decoration-line-bottom text-white">Contact now for more details</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end section */}
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchAllCandidates: actions.fetchall
}

export default connect(mapStateToProps, mapActionToProps)(Home);